import { API, Auth } from "aws-amplify";

export async function createCognitoUser(userParams) {
  try {
    let apiName = "AdminQueries";
    let path = "/createUser";
    let init = {
      body: {
        username: userParams.email,
        email: userParams.email,
        // password:
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, init);
  } catch (error) {
    return error;
  }
}

export async function addCognitoUserToGroup(userParams, group) {
  const params = {
    username: userParams.email,
    groupname: group,
  };

  try {
    let apiName = "AdminQueries";
    let path = "/addUserToGroup";
    let myInit = {
      body: params,
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, myInit);
  } catch (error) {
    return error;
  }
}

export async function disableCognitoUser(userParams) {
  try {
    let apiName = "AdminQueries";
    let path = "/disableUser";
    let init = {
      body: {
        username: userParams.email,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, init);
  } catch (error) {
    return error;
  }
}

export async function deleteCognitoUser(userParams) {
  console.log("deleteCognitoUser", userParams);
  try {
    let apiName = "AdminQueries";
    let path = "/deleteUser";
    let init = {
      body: {
        username: userParams.email,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, init);
  } catch (error) {
    return error;
  }
}
