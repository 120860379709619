const initialProspectCriteria = [
  {
    birthYearRange: [1978, 1996],
    retirementSavings: 999999,
  },
  {
    birthYearRange: [1942, 1977],
    retirementSavings: 249999,
  },
];

export default {
  methods: {
    parseCriteriaData(advisor) {
      const criteria = advisor.prospectCriteria;

      if (criteria) {
        const parsedCriteria = JSON.parse(criteria);
        this.$set(advisor, "prospectCriteria", parsedCriteria);
      } else {
        this.$set(advisor, "prospectCriteria", initialProspectCriteria);
      }

      return advisor;
    },
  },
};
